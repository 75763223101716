import React, { useCallback } from 'react'
import {
  CardWrapper,
  CardContainer,
  OptionContainer,
  SubcategoryTitle,
  OptionContainerDisabled,
  CardContainerDisabled
} from './alert-subcategory.styles'
import { Checkbox } from '@scuf/common'
import { Subtitle } from 'Themes/ScufStyledComponents'
import LockedCardAlerts from 'Components/Locked/LockedAlerts/locked-card-alerts'


const AlertSubcategory = ({
  alertInfo,
  saveAlerts,
  selectedOrgId,
  tabType,
  t,
  enabledChecked,
  typeAlert,
  hasFeatureAccess
}) => {
  const subcategory = []
  alertInfo.forEach(alert => subcategory.push(alert.subCategory))
  const subCategoryValues = Array.from(new Set(subcategory))

  const onChangeCheckbox = (value, type) => {
    const alertList = alertInfo
      .filter(alert => alert.subCategory === type)
      .map(alert => ({
        ruleId: alert.id,
        organizationId: selectedOrgId,
        enabled: tabType === 'enabled' ? value : alert.enabled,
        notificationEmail: tabType === 'notificationEmail' ? value : alert.notificationEmail
      }))
    saveAlerts(alertList)
  }

  const CardCategory = ({ alert }) => {
    return (
      <OptionContainer>
        <Checkbox
          disabled={(!enabledChecked && typeAlert) || !hasFeatureAccess(alert.deviceType, alert.subCategory)}
          label={alert.description}
          checked={alert[tabType] && hasFeatureAccess(alert.deviceType, alert.subCategory)}
          onChange={(value) => {
            const alertList = [{
              ruleId: alert.id,
              organizationId: selectedOrgId,
              enabled: tabType === 'enabled' ? value : alert.enabled,
              notificationEmail: tabType === 'notificationEmail' ? value : alert.notificationEmail
            }]
            saveAlerts(alertList)
          }}
        />

      </OptionContainer>
    )
  }

  const CardCategoryDisabled = ({ alert, isblur }) => {
    return (
      <OptionContainerDisabled blur={isblur}>
        <Checkbox
          label={alert.description}
          disabled='true'
        />
      </OptionContainerDisabled>
    )
  }

  return (
    <CardWrapper>
      {subCategoryValues.map(type => {
        const subCategoryOptions = alertInfo.filter(alert => alert.subCategory === type)
        const enabled = subCategoryOptions.filter(alert => alert[tabType]).length > 0
        const deviceType = subCategoryOptions[0].deviceType
        const hasAccess = hasFeatureAccess(deviceType, type)

        if (hasAccess) {
          return (
            <CardContainer key={type}>
              <SubcategoryTitle>
                <Checkbox
                  disabled={!enabledChecked && typeAlert}
                  label={t(`${type}`)}
                  checked={enabled}
                  onChange={(value) => onChangeCheckbox(value, type)}
                />
              </SubcategoryTitle>
              {alertInfo.filter(alert => alert.subCategory === type).map(data =>
                <CardCategory key={data.id} alert={data} />
              )}
            </CardContainer>
          )
        } else if (!hasAccess) {
          return (
            <LockedCardAlerts
              key={type}
              innerCard={
                <CardContainerDisabled>
                  <SubcategoryTitle blur={!hasAccess}>
                    <Checkbox
                      label={t(`${type}`)}
                      disabled='true'
                    />
                  </SubcategoryTitle>
                  {alertInfo.filter(alert => alert.subCategory === type).map(data =>
                    <CardCategoryDisabled key={data.id} alert={data} isblur={!hasAccess} />
                  )}
                </CardContainerDisabled>
              }
            />
          )
        }
      })}
    </CardWrapper>
  )
}

export default AlertSubcategory
