import moment from 'moment'
import { useTranslate } from 'react-translate';

export const formatShortDate = (date) => {
  return moment.utc(date).format("l") // 2/8/2024
}

export const formatDate = (date) => {
  return moment(date).format("ll") // Feb 8, 2024
}

export const differenceBtwTwoDatesByDay = endDate => {
  const currentDate = moment()
  return moment(endDate).diff(currentDate, 'days')
}

export const yearsAdded = (date, yearsAdded) => {
  return moment(date).add({years: yearsAdded}).format("ll");
}

export const currentDateTime = () => {
  return moment().toISOString(true) // 2013-02-04T22:44:30.652Z Not UTC
}

export const DateFormatter = ({ date }) => {
  const t = useTranslate('Configurations')
  let formattedDate = '';
  date === '' ? formattedDate = t('Configurations_DateUnavailable') : formattedDate = moment.utc(date).local().format(t('Configurations_DateTimeFormat'))
  return <div>{formattedDate}</div>
}

export const startDateOneDayBefore = moment().subtract(1, 'days').format('YYYY-MM-DD').toString() + ' 00:00:00'